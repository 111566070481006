import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NzIconModule } from 'ng-zorro-antd/icon';

@Component({
	selector: 'unigana-footer',
	standalone: true,
	imports: [TranslateModule, NzIconModule],
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
	constructor(private _router: Router) {}

	public onLinkClick(event: MouseEvent, link: string): void {
		event.preventDefault();
		event.stopPropagation();
		const fullUrl = this._router.serializeUrl(this._router.createUrlTree([link]));
		window.open(fullUrl, '_blank');
	}
}
