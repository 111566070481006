<ng-container *ngIf="theme === 'white'">
	<div *ngIf="size === 'l'" class="l-size">
		<svg xmlns="http://www.w3.org/2000/svg" width="39" height="41" viewBox="0 0 39 41" fill="none">
			<path
				d="M7.39993 23.3594C8.52527 23.3594 9.43755 22.4471 9.43755 21.3218C9.43755 20.1965 8.52527 19.2842 7.39993 19.2842C6.27458 19.2842 5.3623 20.1965 5.3623 21.3218C5.3623 22.4471 6.27458 23.3594 7.39993 23.3594Z"
				fill="#FCFCFC"
			/>
			<path
				d="M28.4029 23.3594C29.5282 23.3594 30.4405 22.4471 30.4405 21.3218C30.4405 20.1965 29.5282 19.2842 28.4029 19.2842C27.2775 19.2842 26.3652 20.1965 26.3652 21.3218C26.3652 22.4471 27.2775 23.3594 28.4029 23.3594Z"
				fill="#FCFCFC"
			/>
			<path
				d="M18.4497 19.4122C19.5751 19.4122 20.4874 18.4999 20.4874 17.3745C20.4874 16.2492 19.5751 15.3369 18.4497 15.3369C17.3244 15.3369 16.4121 16.2492 16.4121 17.3745C16.4121 18.4999 17.3244 19.4122 18.4497 19.4122Z"
				fill="#FCFCFC"
			/>
			<path
				d="M36.3109 17.2643C34.9142 17.3306 33.7031 15.6731 33.7031 15.6731C33.7031 15.6731 34.7551 13.9095 36.1518 13.8476C37.5485 13.7813 38.7596 15.4388 38.7596 15.4388C38.7596 15.4388 37.7076 17.2024 36.3109 17.2643Z"
				fill="#FCFCFC"
			/>
			<path
				d="M16.0319 15.0235C15.8728 16.4113 14.0385 17.344 14.0385 17.344C14.0385 17.344 12.4694 16.0224 12.6285 14.6301C12.7876 13.2378 14.6219 12.3096 14.6219 12.3096C14.6219 12.3096 16.191 13.6312 16.0319 15.0235Z"
				fill="#FCFCFC"
			/>
			<path
				d="M17.7731 8.35353C17.614 9.74141 15.7797 10.674 15.7797 10.674C15.7797 10.674 14.2106 9.35245 14.3697 7.96015C14.5289 6.56785 16.3632 5.63965 16.3632 5.63965C16.3632 5.63965 17.9323 6.96123 17.7731 8.35353Z"
				fill="#FCFCFC"
			/>
			<path
				d="M13.7106 9.98439C13.5515 11.3723 11.7172 12.3049 11.7172 12.3049C11.7172 12.3049 10.1481 10.9833 10.3072 9.59101C10.4664 8.20313 12.3007 7.27051 12.3007 7.27051C12.3007 7.27051 13.8698 8.59209 13.7106 9.98439Z"
				fill="#FCFCFC"
			/>
			<path
				d="M11.1077 6.44357C10.816 7.80935 8.90653 8.56076 8.90653 8.56076C8.90653 8.56076 7.47003 7.09331 7.76175 5.72753C8.05347 4.36175 9.96291 3.61035 9.96291 3.61035C9.96291 3.61035 11.3994 5.07779 11.1077 6.44357Z"
				fill="#FCFCFC"
			/>
			<path
				d="M4.3719 10.7756C3.80172 9.49827 4.90231 7.76562 4.90231 7.76562C4.90231 7.76562 6.92667 8.10597 7.49685 9.38335C8.06703 10.6607 6.96645 12.3934 6.96645 12.3934C6.96645 12.3934 4.94209 12.053 4.3719 10.7756Z"
				fill="#FCFCFC"
			/>
			<path
				d="M5.33037 17.4762C4.26515 16.5701 4.50825 14.5325 4.50825 14.5325C4.50825 14.5325 6.47957 13.9623 7.54479 14.8684C8.61001 15.7745 8.36691 17.8122 8.36691 17.8122C8.36691 17.8122 6.39559 18.3823 5.33037 17.4762Z"
				fill="#FCFCFC"
			/>
			<path
				d="M2.06869 25.1933C0.817833 24.5657 0.570312 22.5281 0.570312 22.5281C0.570312 22.5281 2.35157 21.507 3.60243 22.1303C4.8533 22.7579 5.10082 24.7955 5.10082 24.7955C5.10082 24.7955 3.31955 25.8165 2.06869 25.1933Z"
				fill="#FCFCFC"
			/>
			<path
				d="M0.181555 19.2796C-0.415146 18.0155 0.650075 16.2607 0.650075 16.2607C0.650075 16.2607 2.68328 16.5569 3.27998 17.821C3.87668 19.0851 2.81146 20.8399 2.81146 20.8399C2.81146 20.8399 0.778255 20.5437 0.181555 19.2796Z"
				fill="#FCFCFC"
			/>
			<path
				d="M0.610798 12.3271C0.906938 10.9613 2.8208 10.2188 2.8208 10.2188C2.8208 10.2188 4.25288 11.6906 3.95232 13.0564C3.65176 14.4222 1.74232 15.1647 1.74232 15.1647C1.74232 15.1647 0.310237 13.6929 0.610798 12.3271Z"
				fill="#FCFCFC"
			/>
			<path
				d="M24.9333 15.7343C24.2659 16.9631 22.2238 17.1487 22.2238 17.1487C22.2238 17.1487 21.2603 15.3365 21.9233 14.1078C22.5907 12.879 24.6327 12.6934 24.6327 12.6934C24.6327 12.6934 25.5963 14.5056 24.9333 15.7343Z"
				fill="#FCFCFC"
			/>
			<path
				d="M26.2204 10.5019C25.553 11.7307 23.5109 11.9163 23.5109 11.9163C23.5109 11.9163 22.5474 10.1041 23.2104 8.87534C23.8778 7.64658 25.9198 7.46094 25.9198 7.46094C25.9198 7.46094 26.8834 9.27314 26.2204 10.5019Z"
				fill="#FCFCFC"
			/>
			<path
				d="M20.903 12.1676C20.6378 13.5422 18.746 14.3334 18.746 14.3334C18.746 14.3334 17.2786 12.8969 17.5438 11.5222C17.809 10.1476 19.7007 9.35645 19.7007 9.35645C19.7007 9.35645 21.1682 10.7929 20.903 12.1676Z"
				fill="#FCFCFC"
			/>
			<path
				d="M30.4538 13.8603C29.7864 15.0891 27.7443 15.2747 27.7443 15.2747C27.7443 15.2747 26.7808 13.4625 27.4438 12.2337C28.1068 11.005 30.1532 10.8193 30.1532 10.8193C30.1532 10.8193 31.1168 12.6315 30.4538 13.8603Z"
				fill="#FCFCFC"
			/>
			<path
				d="M34.9089 12.8974C34.2415 14.1262 32.1994 14.3118 32.1994 14.3118C32.1994 14.3118 31.2359 12.4996 31.8989 11.2708C32.5619 10.0421 34.6083 9.85645 34.6083 9.85645C34.6083 9.85645 35.5719 11.6686 34.9089 12.8974Z"
				fill="#FCFCFC"
			/>
			<path
				d="M15.2271 4.87957C14.0381 5.61329 12.1729 4.75139 12.1729 4.75139C12.1729 4.75139 12.2392 2.70051 13.4281 1.96679C14.6171 1.23307 16.4824 2.09497 16.4824 2.09497C16.4824 2.09497 16.4161 4.14585 15.2271 4.87957Z"
				fill="#FCFCFC"
			/>
			<path
				d="M30.8344 6.40847C32.1957 6.73113 32.9029 8.65826 32.9029 8.65826C32.9029 8.65826 31.4045 10.0638 30.0432 9.74116C28.6818 9.4185 27.9746 7.49137 27.9746 7.49137C27.9746 7.49137 29.473 6.08581 30.8344 6.40847Z"
				fill="#FCFCFC"
			/>
			<path
				d="M21.3265 4.97683C22.6879 5.29949 23.3951 7.22662 23.3951 7.22662C23.3951 7.22662 21.8967 8.63218 20.5354 8.30952C19.174 7.98686 18.4668 6.05973 18.4668 6.05973C18.4668 6.05973 19.9652 4.65417 21.3265 4.97683Z"
				fill="#FCFCFC"
			/>
			<path
				d="M26.7592 2.69558C28.1205 3.01824 28.8277 4.94537 28.8277 4.94537C28.8277 4.94537 27.3293 6.35093 25.968 6.02827C24.6066 5.70561 23.8994 3.77848 23.8994 3.77848C23.8994 3.77848 25.3978 2.37292 26.7592 2.69558Z"
				fill="#FCFCFC"
			/>
			<path
				d="M20.4954 0.507809C21.8877 0.635989 22.8557 2.44819 22.8557 2.44819C22.8557 2.44819 21.5695 4.04823 20.1728 3.91563C18.7761 3.78303 17.8125 1.97525 17.8125 1.97525C17.8125 1.97525 19.0987 0.375209 20.4954 0.507809Z"
				fill="#FCFCFC"
			/>
			<path
				d="M34.4594 17.6618C35.8207 17.9889 36.5235 19.916 36.5235 19.916C36.5235 19.916 35.0207 21.3171 33.6638 20.9901C32.3024 20.663 31.5996 18.7359 31.5996 18.7359C31.5996 18.7359 33.1024 17.3347 34.4594 17.6618Z"
				fill="#FCFCFC"
			/>
			<path
				d="M32.9782 22.0863C34.3484 21.8079 35.8025 23.2576 35.8025 23.2576C35.8025 23.2576 35.0335 25.1626 33.6633 25.4411C32.2931 25.7196 30.8389 24.2698 30.8389 24.2698C30.8389 24.2698 31.6079 22.3648 32.9782 22.0863Z"
				fill="#FCFCFC"
			/>
			<path
				d="M13.5737 38.2947C13.5737 38.2947 10.8554 15.9206 32.8273 16.177C32.8273 16.177 26.7675 17.7903 22.5729 23.3418C22.5729 23.3418 26.7365 23.1164 29.3045 25.1805C29.3045 25.1805 16.2346 24.659 13.5782 38.2947H13.5737Z"
				fill="#FCFCFC"
			/>
			<path
				d="M17.7152 20.915C10.2675 17.3657 8.83985 10.2451 8.83985 10.2451C7.32379 18.0995 13.5162 23.2001 13.5162 23.2001C8.20337 24.2035 4.00879 26.7538 4.00879 26.7538C4.00879 26.7538 8.34481 25.8168 13.7726 28.3185C14.4312 25.7505 15.6246 23.125 17.7108 20.915H17.7152Z"
				fill="#FCFCFC"
			/>
			<path
				d="M21.623 40.4999C18.7544 33.5825 19.5014 29.5957 19.5014 29.5957C18.2506 30.2499 15.1566 33.1405 14.0029 40.4999H21.623Z"
				fill="#FCFCFC"
			/>
		</svg>
	</div>
	<div *ngIf="size === 'm'" class="m-size cursor-pointer" (click)="onLogoClick()">
		<svg xmlns="http://www.w3.org/2000/svg" width="152" height="40" viewBox="0 0 152 40" fill="none">
			<path
				d="M7.42252 22.6804C8.55189 22.6804 9.46742 21.7753 9.46742 20.6587C9.46742 19.5421 8.55189 18.637 7.42252 18.637C6.29315 18.637 5.37762 19.5421 5.37762 20.6587C5.37762 21.7753 6.29315 22.6804 7.42252 22.6804Z"
				fill="#0072B9"
			/>
			<path
				d="M28.5015 22.6804C29.6309 22.6804 30.5464 21.7753 30.5464 20.6587C30.5464 19.5421 29.6309 18.637 28.5015 18.637C27.3721 18.637 26.4566 19.5421 26.4566 20.6587C26.4566 21.7753 27.3721 22.6804 28.5015 22.6804Z"
				fill="#0072B9"
			/>
			<path
				d="M18.5118 18.765C19.6412 18.765 20.5567 17.8599 20.5567 16.7433C20.5567 15.6267 19.6412 14.7216 18.5118 14.7216C17.3825 14.7216 16.4669 15.6267 16.4669 16.7433C16.4669 17.8599 17.3825 18.765 18.5118 18.765Z"
				fill="#0072B9"
			/>
			<path
				d="M36.4371 16.6333C35.0354 16.6991 33.82 15.0545 33.82 15.0545C33.82 15.0545 34.8757 13.3047 36.2775 13.2433C37.6792 13.1775 38.8946 14.8221 38.8946 14.8221C38.8946 14.8221 37.8389 16.5719 36.4371 16.6333Z"
				fill="#0072B9"
			/>
			<path
				d="M16.0859 14.4103C15.9262 15.7874 14.0853 16.7127 14.0853 16.7127C14.0853 16.7127 12.5106 15.4015 12.6703 14.02C12.83 12.6386 14.6709 11.7176 14.6709 11.7176C14.6709 11.7176 16.2456 13.0289 16.0859 14.4103Z"
				fill="#0072B9"
			/>
			<path
				d="M17.8334 7.79206C17.6737 9.16912 15.8329 10.0945 15.8329 10.0945C15.8329 10.0945 14.2582 8.78319 14.4178 7.40175C14.5775 6.02031 16.4184 5.09935 16.4184 5.09935C16.4184 5.09935 17.9931 6.41062 17.8334 7.79206Z"
				fill="#0072B9"
			/>
			<path
				d="M13.7569 9.41032C13.5972 10.7874 11.7564 11.7127 11.7564 11.7127C11.7564 11.7127 10.1817 10.4014 10.3413 9.02001C10.501 7.64295 12.3419 6.71761 12.3419 6.71761C12.3419 6.71761 13.9166 8.02888 13.7569 9.41032Z"
				fill="#0072B9"
			/>
			<path
				d="M11.1439 5.89716C10.8512 7.25229 8.93489 7.99783 8.93489 7.99783C8.93489 7.99783 7.49325 6.54183 7.78602 5.1867C8.07878 3.83158 9.99504 3.08604 9.99504 3.08604C9.99504 3.08604 11.4367 4.54203 11.1439 5.89716Z"
				fill="#0072B9"
			/>
			<path
				d="M4.38407 10.1953C3.81185 8.92791 4.91636 7.20879 4.91636 7.20879C4.91636 7.20879 6.94796 7.54647 7.52017 8.81389C8.09239 10.0813 6.98788 11.8004 6.98788 11.8004C6.98788 11.8004 4.95628 11.4627 4.38407 10.1953Z"
				fill="#0072B9"
			/>
			<path
				d="M5.34663 16.8438C4.27761 15.9448 4.52158 13.923 4.52158 13.923C4.52158 13.923 6.49994 13.3573 7.56897 14.2563C8.638 15.1554 8.39403 17.1771 8.39403 17.1771C8.39403 17.1771 6.41566 17.7428 5.34663 16.8438Z"
				fill="#0072B9"
			/>
			<path
				d="M2.07302 24.5009C0.817685 23.8782 0.56928 21.8564 0.56928 21.8564C0.56928 21.8564 2.35691 20.8434 3.61224 21.4618C4.86757 22.0845 5.11597 24.1062 5.11597 24.1062C5.11597 24.1062 3.32835 25.1193 2.07302 24.5009Z"
				fill="#0072B9"
			/>
			<path
				d="M0.178907 18.6336C-0.419925 17.3793 0.649102 15.6382 0.649102 15.6382C0.649102 15.6382 2.68957 15.9321 3.2884 17.1863C3.88724 18.4406 2.81821 20.1817 2.81821 20.1817C2.81821 20.1817 0.77774 19.8878 0.178907 18.6336Z"
				fill="#0072B9"
			/>
			<path
				d="M0.609283 11.7353C0.906482 10.3802 2.82718 9.64339 2.82718 9.64339C2.82718 9.64339 4.26438 11.1038 3.96275 12.4589C3.66111 13.814 1.74485 14.5508 1.74485 14.5508C1.74485 14.5508 0.307649 13.0904 0.609283 11.7353Z"
				fill="#0072B9"
			/>
			<path
				d="M25.0196 15.1164C24.3498 16.3356 22.3005 16.5198 22.3005 16.5198C22.3005 16.5198 21.3335 14.7217 21.9988 13.5025C22.6686 12.2833 24.718 12.0991 24.718 12.0991C24.718 12.0991 25.685 13.8972 25.0196 15.1164Z"
				fill="#0072B9"
			/>
			<path
				d="M26.3102 9.92343C25.6404 11.1426 23.5911 11.3268 23.5911 11.3268C23.5911 11.3268 22.6241 9.52873 23.2894 8.30955C23.9592 7.09038 26.0086 6.90618 26.0086 6.90618C26.0086 6.90618 26.9756 8.70425 26.3102 9.92343Z"
				fill="#0072B9"
			/>
			<path
				d="M20.9738 11.5771C20.7077 12.941 18.8092 13.726 18.8092 13.726C18.8092 13.726 17.3365 12.3007 17.6026 10.9368C17.8688 9.57292 19.7673 8.78792 19.7673 8.78792C19.7673 8.78792 21.24 10.2132 20.9738 11.5771Z"
				fill="#0072B9"
			/>
			<path
				d="M30.56 13.257C29.8902 14.4762 27.8409 14.6604 27.8409 14.6604C27.8409 14.6604 26.8739 12.8623 27.5392 11.6431C28.2046 10.4239 30.2584 10.2398 30.2584 10.2398C30.2584 10.2398 31.2254 12.0378 30.56 13.257Z"
				fill="#0072B9"
			/>
			<path
				d="M35.0308 12.3C34.361 13.5191 32.3116 13.7033 32.3116 13.7033C32.3116 13.7033 31.3446 11.9053 32.01 10.6861C32.6754 9.4669 34.7291 9.28271 34.7291 9.28271C34.7291 9.28271 35.6961 11.0808 35.0308 12.3Z"
				fill="#0072B9"
			/>
			<path
				d="M15.2784 4.34503C14.0852 5.07303 12.2133 4.21785 12.2133 4.21785C12.2133 4.21785 12.2798 2.18297 13.473 1.45497C14.6662 0.726974 16.5382 1.58215 16.5382 1.58215C16.5382 1.58215 16.4716 3.61704 15.2784 4.34503Z"
				fill="#0072B9"
			/>
			<path
				d="M30.9412 5.86298C32.3074 6.18312 33.0171 8.09521 33.0171 8.09521C33.0171 8.09521 31.5134 9.48981 30.1472 9.16966C28.7809 8.84952 28.0712 6.93743 28.0712 6.93743C28.0712 6.93743 29.5749 5.54283 30.9412 5.86298Z"
				fill="#0072B9"
			/>
			<path
				d="M21.4001 4.44109C22.7663 4.76123 23.476 6.67332 23.476 6.67332C23.476 6.67332 21.9723 8.06792 20.606 7.74778C19.2398 7.42763 18.5301 5.51554 18.5301 5.51554C18.5301 5.51554 20.0338 4.12094 21.4001 4.44109Z"
				fill="#0072B9"
			/>
			<path
				d="M26.8513 2.17804C28.2175 2.49819 28.9272 4.41028 28.9272 4.41028C28.9272 4.41028 27.4235 5.80488 26.0573 5.48473C24.691 5.16459 23.9813 3.2525 23.9813 3.2525C23.9813 3.2525 25.4851 1.8579 26.8513 2.17804Z"
				fill="#0072B9"
			/>
			<path
				d="M20.5659 0.00774794C21.9631 0.134928 22.9346 1.93299 22.9346 1.93299C22.9346 1.93299 21.6438 3.52056 20.242 3.38899C18.8403 3.25742 17.8733 1.46374 17.8733 1.46374C17.8733 1.46374 19.1641 -0.123818 20.5659 0.00774794Z"
				fill="#0072B9"
			/>
			<path
				d="M34.5786 17.0285C35.9448 17.353 36.6501 19.2651 36.6501 19.2651C36.6501 19.2651 35.1419 20.6553 33.7801 20.3308C32.4139 20.0063 31.7086 18.0942 31.7086 18.0942C31.7086 18.0942 33.2168 16.704 34.5786 17.0285Z"
				fill="#0072B9"
			/>
			<path
				d="M33.0925 21.4183C34.4676 21.1421 35.927 22.5805 35.927 22.5805C35.927 22.5805 35.1552 24.4707 33.7801 24.747C32.405 25.0233 30.9456 23.5848 30.9456 23.5848C30.9456 23.5848 31.7174 21.6946 33.0925 21.4183Z"
				fill="#0072B9"
			/>
			<path
				d="M13.6194 37.4996C13.6194 37.4996 10.8914 15.3001 32.9417 15.5544C32.9417 15.5544 26.8603 17.1552 22.6507 22.6634C22.6507 22.6634 26.8292 22.4397 29.4064 24.4878C29.4064 24.4878 16.2897 23.9703 13.6238 37.4996H13.6194Z"
				fill="#0072B9"
			/>
			<path
				d="M17.7756 20.2561C10.3013 16.7345 8.86851 9.66943 8.86851 9.66943C7.34703 17.4625 13.5616 22.5234 13.5616 22.5234C8.22975 23.5189 4.02018 26.0494 4.02018 26.0494C4.02018 26.0494 8.3717 25.1196 13.8189 27.6019C14.4798 25.0539 15.6775 22.4489 17.7712 20.2561H17.7756Z"
				fill="#0072B9"
			/>
			<path
				d="M21.697 39.688C18.8182 32.8247 19.5678 28.8689 19.5678 28.8689C18.3125 29.518 15.2074 32.3861 14.0497 39.688H21.697Z"
				fill="#0072B9"
			/>
			<path
				d="M54.0029 32.5128C54.0029 34.0127 53.6835 35.1266 53.0448 35.8502C52.406 36.5738 51.4168 36.9335 50.0817 36.9335C48.7465 36.9335 47.7529 36.5738 47.1052 35.8502C46.4576 35.1266 46.1294 34.0171 46.1294 32.5128V21.8297H42.3456V32.6751C42.3456 35.1178 43.0021 36.9466 44.3196 38.1658C45.637 39.385 47.5577 39.9946 50.0772 39.9946C52.5968 39.9946 54.5219 39.385 55.8349 38.1658C57.1523 36.9466 57.8088 35.1135 57.8088 32.6751V21.8297H53.9985V32.5128H54.0029Z"
				fill="#0072B9"
			/>
			<path
				d="M72.4201 26.4399C71.6794 26.0803 70.8321 25.8961 69.8829 25.8961C69.0578 25.8961 68.3037 26.0365 67.6206 26.3172C66.933 26.5978 66.3564 27.0276 65.8773 27.6065C65.6201 27.9223 65.4027 28.2863 65.2164 28.6941L65.0789 26.1724H61.7032V39.7325H65.4604V32.5446C65.4604 31.6412 65.6112 30.9176 65.9128 30.3738C66.2144 29.83 66.6092 29.4396 67.0927 29.1941C67.5762 28.9485 68.0731 28.8301 68.5876 28.8301C69.3195 28.8301 69.9139 29.0537 70.3708 29.4967C70.8277 29.9396 71.0583 30.7378 71.0583 31.8955V39.7325H74.8155V30.8386C74.8155 29.7379 74.6025 28.8169 74.1723 28.0845C73.742 27.3521 73.1565 26.8039 72.4157 26.4443L72.4201 26.4399Z"
				fill="#0072B9"
			/>
			<path
				d="M80.5822 20.2294C79.7971 20.2294 79.2115 20.3961 78.8256 20.7294C78.4397 21.0627 78.249 21.567 78.249 22.2336C78.249 22.9002 78.4397 23.3782 78.8256 23.7115C79.2115 24.0448 79.7926 24.2115 80.5822 24.2115C81.3718 24.2115 81.9529 24.0448 82.3388 23.7115C82.7247 23.3782 82.9154 22.8827 82.9154 22.2336C82.9154 21.5845 82.7247 21.0627 82.3388 20.7294C81.9529 20.3961 81.3673 20.2294 80.5822 20.2294Z"
				fill="#0072B9"
			/>
			<path d="M82.4456 26.167H78.6884V39.727H82.4456V26.167Z" fill="#0072B9" />
			<path
				d="M94.4308 32.8641H99.6961V33.3816C99.6961 34.0877 99.5719 34.6973 99.3279 35.2104C99.0795 35.7235 98.7379 36.1489 98.2988 36.4866C97.8596 36.8199 97.3495 37.0742 96.764 37.2453C96.1785 37.4163 95.5486 37.504 94.8699 37.504C93.6279 37.504 92.581 37.2409 91.7294 36.719C90.8777 36.1927 90.2434 35.4297 89.822 34.4298C89.4006 33.4255 89.1921 32.2107 89.1921 30.781C89.1921 29.3513 89.4316 28.1628 89.9063 27.1629C90.3809 26.163 91.0463 25.3956 91.8935 24.8737C92.7452 24.3518 93.7344 24.0887 94.8699 24.0887C96.0055 24.0887 96.968 24.3387 97.7621 24.8342C98.5561 25.3298 99.1283 26.0753 99.4743 27.0708L102.189 25.7947C101.493 24.3474 100.565 23.2817 99.4033 22.5932C98.2411 21.9047 96.7197 21.5626 94.8389 21.5626C93.1178 21.5626 91.5963 21.9398 90.2744 22.6897C88.9481 23.4396 87.9235 24.5009 87.2004 25.8736C86.4774 27.2463 86.1181 28.8821 86.1181 30.781C86.1181 32.6799 86.4641 34.364 87.1605 35.7279C87.8569 37.0918 88.8239 38.1443 90.0659 38.8855C91.308 39.6266 92.7807 39.9994 94.484 39.9994C96.0188 39.9994 97.2963 39.6836 98.3077 39.0521C99.0085 38.6135 99.5719 38.0522 99.9977 37.3724L100.104 39.7275H102.3V30.5091H94.4263V32.8685L94.4308 32.8641Z"
				fill="#0072B9"
			/>
			<path
				d="M117.417 36.7722V30.7246C117.417 29.6238 117.173 28.716 116.69 28.0144C116.206 27.3083 115.541 26.782 114.702 26.4268C113.86 26.0759 112.893 25.8961 111.797 25.8961C110.187 25.8961 108.874 26.2294 107.862 26.9004C106.847 27.5714 106.168 28.4573 105.818 29.5581L108.288 30.5887C108.563 29.7379 108.994 29.1239 109.579 28.7467C110.165 28.3652 110.87 28.1766 111.691 28.1766C112.569 28.1766 113.256 28.4266 113.762 28.9222C114.263 29.4177 114.516 30.1194 114.516 31.0228V31.5403L109.632 32.4876C108.408 32.7244 107.463 33.1673 106.793 33.8164C106.124 34.4655 105.791 35.2987 105.791 36.3118C105.791 37.1231 105.986 37.8072 106.381 38.3598C106.776 38.9124 107.312 39.3246 108 39.5921C108.687 39.864 109.459 40 110.315 40C111.012 40 111.695 39.886 112.374 39.6623C113.048 39.4387 113.642 39.0922 114.157 38.6317C114.361 38.4475 114.534 38.237 114.68 38.0134C114.747 38.2589 114.84 38.487 114.964 38.6887C115.23 39.1141 115.594 39.4299 116.06 39.636C116.526 39.8421 117.053 39.9474 117.639 39.9474C118.278 39.9474 118.81 39.8465 119.231 39.6492L119.396 37.6406C119.085 37.7327 118.766 37.7765 118.437 37.7765C117.759 37.7765 117.422 37.4432 117.422 36.7722H117.417ZM114.223 36.11C114.033 36.5003 113.762 36.8161 113.416 37.0573C113.07 37.3029 112.68 37.4783 112.249 37.588C111.819 37.6976 111.376 37.7502 110.919 37.7502C110.262 37.7502 109.734 37.6011 109.34 37.3029C108.945 37.0047 108.75 36.6012 108.75 36.0969C108.75 35.5925 108.94 35.1759 109.326 34.904C109.712 34.6321 110.284 34.426 111.056 34.2813L114.512 33.5796V34.7154C114.512 35.2592 114.414 35.7241 114.223 36.11Z"
				fill="#0072B9"
			/>
			<path
				d="M132.211 26.4531C131.434 26.0847 130.561 25.8961 129.594 25.8961C128.844 25.8961 128.134 26.0146 127.469 26.247C126.799 26.4838 126.227 26.8566 125.739 27.3741C125.424 27.7074 125.167 28.0977 124.949 28.5406L124.821 26.168H122.217V39.7281H125.153V32.5139C125.153 31.4833 125.34 30.6632 125.717 30.0449C126.089 29.4309 126.564 28.9836 127.127 28.7029C127.695 28.4222 128.281 28.2819 128.884 28.2819C129.727 28.2819 130.423 28.5406 130.982 29.0537C131.541 29.5712 131.82 30.4966 131.82 31.8341V39.7237H134.757V30.9395C134.757 29.7993 134.526 28.8608 134.069 28.1196C133.612 27.3785 132.996 26.8215 132.22 26.4531H132.211Z"
				fill="#0072B9"
			/>
			<path
				d="M151.041 37.7765C150.362 37.7765 150.025 37.4432 150.025 36.7722V30.7246C150.025 29.6238 149.781 28.716 149.297 28.0144C148.814 27.3083 148.149 26.782 147.31 26.4268C146.467 26.0759 145.5 25.8961 144.405 25.8961C142.795 25.8961 141.482 26.2294 140.47 26.9004C139.454 27.5714 138.776 28.4573 138.425 29.5581L140.896 30.5887C141.171 29.7379 141.601 29.1239 142.187 28.7467C142.772 28.3652 143.478 28.1766 144.298 28.1766C145.177 28.1766 145.864 28.4266 146.37 28.9222C146.871 29.4177 147.124 30.1194 147.124 31.0228V31.5403L142.24 32.4876C141.016 32.7244 140.071 33.1673 139.401 33.8164C138.736 34.4655 138.399 35.2987 138.399 36.3118C138.399 37.1231 138.594 37.8072 138.989 38.3598C139.383 38.9124 139.92 39.3246 140.608 39.5921C141.295 39.864 142.067 40 142.923 40C143.62 40 144.303 39.886 144.981 39.6623C145.656 39.4387 146.25 39.0922 146.765 38.6317C146.969 38.4475 147.142 38.237 147.288 38.0134C147.355 38.2589 147.448 38.487 147.572 38.6887C147.838 39.1141 148.202 39.4299 148.668 39.636C149.133 39.8421 149.661 39.9474 150.247 39.9474C150.885 39.9474 151.418 39.8465 151.839 39.6492L152.003 37.6406C151.693 37.7327 151.373 37.7765 151.045 37.7765H151.041ZM146.831 36.11C146.64 36.5003 146.37 36.8161 146.024 37.0573C145.678 37.3029 145.287 37.4783 144.857 37.588C144.427 37.6976 143.983 37.7502 143.526 37.7502C142.87 37.7502 142.342 37.6011 141.947 37.3029C141.553 37.0047 141.357 36.6012 141.357 36.0969C141.357 35.5925 141.548 35.1759 141.934 34.904C142.32 34.6321 142.892 34.426 143.664 34.2813L147.119 33.5796V34.7154C147.119 35.2592 147.022 35.7241 146.831 36.11Z"
				fill="#0072B9"
			/>
		</svg>
	</div>
	<div *ngIf="size === 's'" class="s-size cursor-pointer" (click)="onLogoClick()">
		<svg width="72" height="73" viewBox="0 0 72 73" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_1171_10726)">
				<path
					d="M45.7355 0.5H26.2645C11.759 0.5 0 12.259 0 26.7645V46.2355C0 60.741 11.759 72.5 26.2645 72.5H45.7355C60.241 72.5 72 60.741 72 46.2355V26.7645C72 12.259 60.241 0.5 45.7355 0.5Z"
					fill="#017F70"
				/>
				<path
					d="M24.397 39.3594C25.5223 39.3594 26.4346 38.4471 26.4346 37.3218C26.4346 36.1965 25.5223 35.2842 24.397 35.2842C23.2716 35.2842 22.3594 36.1965 22.3594 37.3218C22.3594 38.4471 23.2716 39.3594 24.397 39.3594Z"
					fill="#FCFCFC"
				/>
				<path
					d="M45.4048 39.3594C46.5302 39.3594 47.4424 38.4471 47.4424 37.3218C47.4424 36.1965 46.5302 35.2842 45.4048 35.2842C44.2795 35.2842 43.3672 36.1965 43.3672 37.3218C43.3672 38.4471 44.2795 39.3594 45.4048 39.3594Z"
					fill="#FCFCFC"
				/>
				<path
					d="M35.4517 35.4122C36.577 35.4122 37.4893 34.4999 37.4893 33.3745C37.4893 32.2492 36.577 31.3369 35.4517 31.3369C34.3263 31.3369 33.4141 32.2492 33.4141 33.3745C33.4141 34.4999 34.3263 35.4122 35.4517 35.4122Z"
					fill="#FCFCFC"
				/>
				<path
					d="M53.3109 33.2643C51.9142 33.3306 50.7031 31.6731 50.7031 31.6731C50.7031 31.6731 51.7551 29.9095 53.1518 29.8476C54.5485 29.7813 55.7596 31.4388 55.7596 31.4388C55.7596 31.4388 54.7076 33.2024 53.3109 33.2643Z"
					fill="#FCFCFC"
				/>
				<path
					d="M33.0319 31.0235C32.8728 32.4113 31.0385 33.344 31.0385 33.344C31.0385 33.344 29.4694 32.0224 29.6285 30.6301C29.7876 29.2378 31.6219 28.3096 31.6219 28.3096C31.6219 28.3096 33.191 29.6312 33.0319 31.0235Z"
					fill="#FCFCFC"
				/>
				<path
					d="M34.7741 24.3535C34.615 25.7414 32.7807 26.674 32.7807 26.674C32.7807 26.674 31.2116 25.3525 31.3707 23.9602C31.5298 22.5678 33.3641 21.6396 33.3641 21.6396C33.3641 21.6396 34.9332 22.9612 34.7741 24.3535Z"
					fill="#FCFCFC"
				/>
				<path
					d="M30.7116 25.9844C30.5525 27.3723 28.7182 28.3049 28.7182 28.3049C28.7182 28.3049 27.1491 26.9833 27.3082 25.591C27.4673 24.2031 29.3016 23.2705 29.3016 23.2705C29.3016 23.2705 30.8707 24.5921 30.7116 25.9844Z"
					fill="#FCFCFC"
				/>
				<path
					d="M28.1116 22.4436C27.8199 23.8094 25.9104 24.5608 25.9104 24.5608C25.9104 24.5608 24.4739 23.0933 24.7657 21.7275C25.0574 20.3618 26.9668 19.6104 26.9668 19.6104C26.9668 19.6104 28.4033 21.0778 28.1116 22.4436Z"
					fill="#FCFCFC"
				/>
				<path
					d="M21.3748 26.7756C20.8047 25.4983 21.9052 23.7656 21.9052 23.7656C21.9052 23.7656 23.9296 24.106 24.4998 25.3833C25.07 26.6607 23.9694 28.3934 23.9694 28.3934C23.9694 28.3934 21.945 28.053 21.3748 26.7756Z"
					fill="#FCFCFC"
				/>
				<path
					d="M22.3304 33.4762C21.2652 32.5701 21.5083 30.5325 21.5083 30.5325C21.5083 30.5325 23.4796 29.9623 24.5448 30.8684C25.61 31.7745 25.3669 33.8122 25.3669 33.8122C25.3669 33.8122 23.3956 34.3823 22.3304 33.4762Z"
					fill="#FCFCFC"
				/>
				<path
					d="M19.0687 41.1933C17.8178 40.5657 17.5703 38.5281 17.5703 38.5281C17.5703 38.5281 19.3516 37.507 20.6024 38.1303C21.8533 38.7579 22.1008 40.7955 22.1008 40.7955C22.1008 40.7955 20.3196 41.8165 19.0687 41.1933Z"
					fill="#FCFCFC"
				/>
				<path
					d="M17.1816 35.2796C16.5849 34.0155 17.6501 32.2607 17.6501 32.2607C17.6501 32.2607 19.6833 32.5569 20.28 33.821C20.8767 35.0851 19.8115 36.8399 19.8115 36.8399C19.8115 36.8399 17.7783 36.5437 17.1816 35.2796Z"
					fill="#FCFCFC"
				/>
				<path
					d="M17.6118 28.3271C17.9079 26.9613 19.8218 26.2188 19.8218 26.2188C19.8218 26.2188 21.2539 27.6906 20.9533 29.0564C20.6527 30.4222 18.7433 31.1647 18.7433 31.1647C18.7433 31.1647 17.3112 29.6929 17.6118 28.3271Z"
					fill="#FCFCFC"
				/>
				<path
					d="M41.9304 31.7343C41.2629 32.9631 39.2209 33.1487 39.2209 33.1487C39.2209 33.1487 38.2573 31.3365 38.9203 30.1078C39.5878 28.879 41.6298 28.6934 41.6298 28.6934C41.6298 28.6934 42.5934 30.5056 41.9304 31.7343Z"
					fill="#FCFCFC"
				/>
				<path
					d="M43.2194 26.5019C42.552 27.7307 40.51 27.9163 40.51 27.9163C40.51 27.9163 39.5464 26.1041 40.2094 24.8753C40.8768 23.6466 42.9189 23.4609 42.9189 23.4609C42.9189 23.4609 43.8824 25.2731 43.2194 26.5019Z"
					fill="#FCFCFC"
				/>
				<path
					d="M37.8991 28.1676C37.6339 29.5422 35.7421 30.3334 35.7421 30.3334C35.7421 30.3334 34.2747 28.8969 34.5399 27.5222C34.8051 26.1476 36.6968 25.3564 36.6968 25.3564C36.6968 25.3564 38.1643 26.7929 37.8991 28.1676Z"
					fill="#FCFCFC"
				/>
				<path
					d="M47.4538 29.8603C46.7864 31.0891 44.7443 31.2747 44.7443 31.2747C44.7443 31.2747 43.7808 29.4625 44.4438 28.2337C45.1068 27.005 47.1532 26.8193 47.1532 26.8193C47.1532 26.8193 48.1168 28.6315 47.4538 29.8603Z"
					fill="#FCFCFC"
				/>
				<path
					d="M51.9069 28.8974C51.2395 30.1262 49.1975 30.3118 49.1975 30.3118C49.1975 30.3118 48.2339 28.4996 48.8969 27.2708C49.5599 26.0421 51.6064 25.8564 51.6064 25.8564C51.6064 25.8564 52.5699 27.6686 51.9069 28.8974Z"
					fill="#FCFCFC"
				/>
				<path
					d="M32.2261 20.8796C31.0371 21.6133 29.1719 20.7514 29.1719 20.7514C29.1719 20.7514 29.2382 18.7005 30.4272 17.9668C31.6161 17.2331 33.4814 18.095 33.4814 18.095C33.4814 18.095 33.4151 20.1458 32.2261 20.8796Z"
					fill="#FCFCFC"
				/>
				<path
					d="M47.8363 22.4085C49.1977 22.7311 49.9049 24.6583 49.9049 24.6583C49.9049 24.6583 48.4065 26.0638 47.0451 25.7412C45.6838 25.4185 44.9766 23.4914 44.9766 23.4914C44.9766 23.4914 46.4749 22.0858 47.8363 22.4085Z"
					fill="#FCFCFC"
				/>
				<path
					d="M38.3285 20.9768C39.6899 21.2995 40.3971 23.2266 40.3971 23.2266C40.3971 23.2266 38.8987 24.6322 37.5373 24.3095C36.176 23.9869 35.4688 22.0597 35.4688 22.0597C35.4688 22.0597 36.9671 20.6542 38.3285 20.9768Z"
					fill="#FCFCFC"
				/>
				<path
					d="M43.7582 18.6956C45.1195 19.0182 45.8267 20.9454 45.8267 20.9454C45.8267 20.9454 44.3284 22.3509 42.967 22.0283C41.6056 21.7056 40.8984 19.7785 40.8984 19.7785C40.8984 19.7785 42.3968 18.3729 43.7582 18.6956Z"
					fill="#FCFCFC"
				/>
				<path
					d="M37.4954 16.5078C38.8877 16.636 39.8557 18.4482 39.8557 18.4482C39.8557 18.4482 38.5695 20.0482 37.1728 19.9156C35.7761 19.783 34.8125 17.9753 34.8125 17.9753C34.8125 17.9753 36.0987 16.3752 37.4954 16.5078Z"
					fill="#FCFCFC"
				/>
				<path
					d="M51.4613 33.6618C52.8227 33.9889 53.5254 35.916 53.5254 35.916C53.5254 35.916 52.0226 37.3171 50.6657 36.9901C49.3043 36.663 48.6016 34.7359 48.6016 34.7359C48.6016 34.7359 50.1044 33.3347 51.4613 33.6618Z"
					fill="#FCFCFC"
				/>
				<path
					d="M49.9752 38.0863C51.3454 37.8079 52.7996 39.2576 52.7996 39.2576C52.7996 39.2576 52.0305 41.1626 50.6603 41.4411C49.2901 41.7196 47.8359 40.2698 47.8359 40.2698C47.8359 40.2698 48.605 38.3648 49.9752 38.0863Z"
					fill="#FCFCFC"
				/>
				<path
					d="M30.5757 54.2947C30.5757 54.2947 27.8574 31.9206 49.8292 32.177C49.8292 32.177 43.7694 33.7903 39.5748 39.3418C39.5748 39.3418 43.7385 39.1164 46.3065 41.1805C46.3065 41.1805 33.2365 40.659 30.5801 54.2947H30.5757Z"
					fill="#FCFCFC"
				/>
				<path
					d="M34.7142 36.915C27.2665 33.3657 25.8389 26.2451 25.8389 26.2451C24.3228 34.0995 30.5152 39.2001 30.5152 39.2001C25.2024 40.2035 21.0078 42.7538 21.0078 42.7538C21.0078 42.7538 25.3438 41.8168 30.7716 44.3185C31.4302 41.7505 32.6236 39.125 34.7098 36.915H34.7142Z"
					fill="#FCFCFC"
				/>
				<path
					d="M38.6201 56.4999C35.7515 49.5825 36.4985 45.5957 36.4985 45.5957C35.2476 46.2499 32.1536 49.1405 31 56.4999H38.6201Z"
					fill="#FCFCFC"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1171_10726">
					<rect width="72" height="72" fill="white" transform="translate(0 0.5)" />
				</clipPath>
			</defs>
		</svg>

		<!-- <svg xmlns="http://www.w3.org/2000/svg" width="27" height="28" viewBox="0 0 27 28" fill="none">
			<path
				d="M5.76564 15.9063C6.51587 15.9063 7.12406 15.2981 7.12406 14.5479C7.12406 13.7976 6.51587 13.1895 5.76564 13.1895C5.01541 13.1895 4.40723 13.7976 4.40723 14.5479C4.40723 15.2981 5.01541 15.9063 5.76564 15.9063Z"
				fill="#FCFCFC"
			/>
			<path
				d="M19.7686 15.9063C20.5188 15.9063 21.127 15.2981 21.127 14.5479C21.127 13.7976 20.5188 13.1895 19.7686 13.1895C19.0183 13.1895 18.4102 13.7976 18.4102 14.5479C18.4102 15.2981 19.0183 15.9063 19.7686 15.9063Z"
				fill="#FCFCFC"
			/>
			<path
				d="M13.1328 13.2754C13.8831 13.2754 14.4912 12.6672 14.4912 11.917C14.4912 11.1668 13.8831 10.5586 13.1328 10.5586C12.3826 10.5586 11.7744 11.1668 11.7744 11.917C11.7744 12.6672 12.3826 13.2754 13.1328 13.2754Z"
				fill="#FCFCFC"
			/>
			<path
				d="M25.0403 11.8425C24.1091 11.8867 23.3018 10.7817 23.3018 10.7817C23.3018 10.7817 24.0031 9.60602 24.9342 9.56477C25.8654 9.52057 26.6727 10.6256 26.6727 10.6256C26.6727 10.6256 25.9714 11.8013 25.0403 11.8425Z"
				fill="#FCFCFC"
			/>
			<path
				d="M11.5206 10.3493C11.4145 11.2745 10.1917 11.8963 10.1917 11.8963C10.1917 11.8963 9.14562 11.0152 9.2517 10.087C9.35778 9.15884 10.5806 8.54004 10.5806 8.54004C10.5806 8.54004 11.6267 9.42109 11.5206 10.3493Z"
				fill="#FCFCFC"
			/>
			<path
				d="M12.6818 5.90301C12.5757 6.82826 11.3528 7.45001 11.3528 7.45001C11.3528 7.45001 10.3067 6.56895 10.4128 5.64075C10.5189 4.71255 11.7418 4.09375 11.7418 4.09375C11.7418 4.09375 12.7878 4.9748 12.6818 5.90301Z"
				fill="#FCFCFC"
			/>
			<path
				d="M9.97375 6.98992C9.86767 7.91517 8.64481 8.53692 8.64481 8.53692C8.64481 8.53692 7.59874 7.65587 7.70482 6.72766C7.8109 5.80241 9.03377 5.18066 9.03377 5.18066C9.03377 5.18066 10.0798 6.06172 9.97375 6.98992Z"
				fill="#FCFCFC"
			/>
			<path
				d="M8.23814 4.63003C8.04366 5.54055 6.7707 6.04148 6.7707 6.04148C6.7707 6.04148 5.81303 5.06319 6.00751 4.15267C6.20199 3.24214 7.47495 2.74121 7.47495 2.74121C7.47495 2.74121 8.43262 3.7195 8.23814 4.63003Z"
				fill="#FCFCFC"
			/>
			<path
				d="M3.74696 7.51742C3.36684 6.66584 4.10056 5.51074 4.10056 5.51074C4.10056 5.51074 5.45013 5.73764 5.83026 6.58922C6.21038 7.44081 5.47666 8.5959 5.47666 8.5959C5.47666 8.5959 4.12708 8.36901 3.74696 7.51742Z"
				fill="#FCFCFC"
			/>
			<path
				d="M4.38724 11.9848C3.67709 11.3807 3.83916 10.0223 3.83916 10.0223C3.83916 10.0223 5.15337 9.64221 5.86352 10.2463C6.57367 10.8503 6.4116 12.2088 6.4116 12.2088C6.4116 12.2088 5.09739 12.5889 4.38724 11.9848Z"
				fill="#FCFCFC"
			/>
			<path
				d="M2.21279 17.1295C1.37888 16.7111 1.21387 15.3527 1.21387 15.3527C1.21387 15.3527 2.40137 14.672 3.23528 15.0875C4.06919 15.5059 4.2342 16.8643 4.2342 16.8643C4.2342 16.8643 3.0467 17.545 2.21279 17.1295Z"
				fill="#FCFCFC"
			/>
			<path
				d="M0.954044 13.1864C0.556244 12.3437 1.26639 11.1738 1.26639 11.1738C1.26639 11.1738 2.62186 11.3713 3.01966 12.214C3.41746 13.0567 2.70731 14.2266 2.70731 14.2266C2.70731 14.2266 1.35184 14.0292 0.954044 13.1864Z"
				fill="#FCFCFC"
			/>
			<path
				d="M1.23956 8.55204C1.43698 7.64152 2.71289 7.14648 2.71289 7.14648C2.71289 7.14648 3.66761 8.12772 3.46724 9.03825C3.26686 9.94877 1.9939 10.4438 1.9939 10.4438C1.9939 10.4438 1.03918 9.46257 1.23956 8.55204Z"
				fill="#FCFCFC"
			/>
			<path
				d="M17.4559 10.8232C17.0109 11.6424 15.6495 11.7661 15.6495 11.7661C15.6495 11.7661 15.0072 10.558 15.4492 9.73883C15.8941 8.91966 17.2555 8.7959 17.2555 8.7959C17.2555 8.7959 17.8979 10.004 17.4559 10.8232Z"
				fill="#FCFCFC"
			/>
			<path
				d="M18.3133 7.33493C17.8683 8.1541 16.507 8.27786 16.507 8.27786C16.507 8.27786 15.8646 7.06973 16.3066 6.25055C16.7515 5.43138 18.1129 5.30762 18.1129 5.30762C18.1129 5.30762 18.7553 6.51575 18.3133 7.33493Z"
				fill="#FCFCFC"
			/>
			<path
				d="M14.7677 8.44537C14.5909 9.36178 13.3297 9.88924 13.3297 9.88924C13.3297 9.88924 12.3514 8.93157 12.5282 8.01516C12.705 7.09874 13.9662 6.57129 13.9662 6.57129C13.9662 6.57129 14.9445 7.52896 14.7677 8.44537Z"
				fill="#FCFCFC"
			/>
			<path
				d="M21.1355 9.57418C20.6906 10.3934 19.3292 10.5171 19.3292 10.5171C19.3292 10.5171 18.6869 9.30898 19.1289 8.48981C19.5709 7.67063 20.9352 7.54688 20.9352 7.54688C20.9352 7.54688 21.5775 8.75501 21.1355 9.57418Z"
				fill="#FCFCFC"
			/>
			<path
				d="M24.1062 8.93161C23.6613 9.75078 22.2999 9.87454 22.2999 9.87454C22.2999 9.87454 21.6576 8.66641 22.0996 7.84723C22.5416 7.02806 23.9059 6.9043 23.9059 6.9043C23.9059 6.9043 24.5482 8.11243 24.1062 8.93161Z"
				fill="#FCFCFC"
			/>
			<path
				d="M10.9844 3.58638C10.1917 4.07553 8.94824 3.50093 8.94824 3.50093C8.94824 3.50093 8.99244 2.13367 9.7851 1.64453C10.5778 1.15538 11.8212 1.72998 11.8212 1.72998C11.8212 1.72998 11.777 3.09723 10.9844 3.58638Z"
				fill="#FCFCFC"
			/>
			<path
				d="M21.3889 4.6063C22.2965 4.82141 22.768 6.10615 22.768 6.10615C22.768 6.10615 21.769 7.0432 20.8615 6.82809C19.9539 6.61298 19.4824 5.32823 19.4824 5.32823C19.4824 5.32823 20.4813 4.39119 21.3889 4.6063Z"
				fill="#FCFCFC"
			/>
			<path
				d="M15.051 3.65122C15.9586 3.86633 16.4301 5.15108 16.4301 5.15108C16.4301 5.15108 15.4311 6.08812 14.5236 5.87301C13.616 5.6579 13.1445 4.37316 13.1445 4.37316C13.1445 4.37316 14.1435 3.43612 15.051 3.65122Z"
				fill="#FCFCFC"
			/>
			<path
				d="M18.6721 2.13071C19.5797 2.34582 20.0512 3.63057 20.0512 3.63057C20.0512 3.63057 19.0522 4.56761 18.1447 4.3525C17.2371 4.1374 16.7656 2.85265 16.7656 2.85265C16.7656 2.85265 17.7645 1.91561 18.6721 2.13071Z"
				fill="#FCFCFC"
			/>
			<path
				d="M14.4966 0.672198C15.4248 0.757651 16.0702 1.96579 16.0702 1.96579C16.0702 1.96579 15.2127 3.03248 14.2815 2.94408C13.3504 2.85568 12.708 1.65049 12.708 1.65049C12.708 1.65049 13.5655 0.583798 14.4966 0.672198Z"
				fill="#FCFCFC"
			/>
			<path
				d="M23.8059 12.1082C24.7135 12.3262 25.182 13.611 25.182 13.611C25.182 13.611 24.1801 14.5451 23.2755 14.327C22.3679 14.109 21.8994 12.8242 21.8994 12.8242C21.8994 12.8242 22.9013 11.8901 23.8059 12.1082Z"
				fill="#FCFCFC"
			/>
			<path
				d="M22.8178 15.0579C23.7313 14.8722 24.7007 15.8387 24.7007 15.8387C24.7007 15.8387 24.188 17.1087 23.2745 17.2944C22.3611 17.48 21.3916 16.5135 21.3916 16.5135C21.3916 16.5135 21.9043 15.2435 22.8178 15.0579Z"
				fill="#FCFCFC"
			/>
			<path
				d="M9.88282 25.8628C9.88282 25.8628 8.07062 10.9468 22.7185 11.1177C22.7185 11.1177 18.6786 12.1932 15.8822 15.8942C15.8822 15.8942 18.658 15.7439 20.37 17.12C20.37 17.12 11.6567 16.7723 9.88577 25.8628H9.88282Z"
				fill="#FCFCFC"
			/>
			<path
				d="M12.6435 14.2773C7.67834 11.9111 6.72657 7.16406 6.72657 7.16406C5.71586 12.4003 9.84415 15.8007 9.84415 15.8007C6.30225 16.4696 3.50586 18.1699 3.50586 18.1699C3.50586 18.1699 6.39654 17.5452 10.0151 19.213C10.4541 17.501 11.2497 15.7507 12.6405 14.2773H12.6435Z"
				fill="#FCFCFC"
			/>
			<path
				d="M15.248 27.3339C13.3356 22.7223 13.8336 20.0645 13.8336 20.0645C12.9997 20.5006 10.937 22.4277 10.168 27.3339H15.248Z"
				fill="#FCFCFC"
			/>
		</svg> -->
	</div>
</ng-container>
